// Daterange picker, Aaron Sawyer, Marathon Consulting, 2019.
// using
// jQuery Plugin Boilerplate
// A boilerplate for jumpstarting jQuery plugins development
// version 2.0, July 8th, 2011
// by Stefan Gabos

//call like so:
//values go into each input
// $.daterange($(".date-inline-target"), { startDateDom: $startDateDom, endDateDom: $endDateDom });
// or, values go aggregated into one
// $.daterange((".date-inline-target"), { dateDom: $dateDom });
//popup mode
//$.daterange(null, { startDateDom: $startDateDom, endDateDom: $endDateDom });
//can also pass an onSelect callback in the options object.
; (function ($) {

    // we need attach the plugin to jQuery's namespace or otherwise it would not be
    // available outside this function's scope
    // "el" should be a jQuery object or a collection of jQuery objects as returned by
    // jQuery's selector engine
    $.daterange = function (el, options) {

        // to avoid confusions, use "plugin" to reference the
        // current instance of the  object
        var plugin = this;
        plugin.values = {
            startDate: "",
            endDate: ""
        };

        // plugin's default options
        // this is private property and is accessible only from inside the plugin
        var defaults = {

            startDateDom: null,
            endDateDom: null,
            dateDom: null,
            startHiddenField: null,
            endHiddenField: null,
            onSelect: null,
            customSetOnSelect: null, //optional, can return an externally calculated start / end value from a selection input. return array of 2 values, 0 = start 1 = end
            onSelectChange: true,
            beforeShowDay: null,
            onChangeMonthYear: null,
            getStartValue: function () { return plugin.values.startDate; },
            getEndValue: function () { return plugin.values.endDate; },
            getUpdateMode: function () { return plugin.updateMode; },
            startDate: "",
            endDate: "",
            changeYear: false,
            numberOfMonths: 1,
            closeSelectedDelay: 0, //if not keepOpen, ms to close
            minDate: null, //min start date
            maxDate: null, //max start date
            renderClearButton: true,
            twoWayBinding: true, //allow textbox entry to alter datepicker
            onDateParseError: null, //fire on textbox typing parse errors
            onTextEntryValidator: null, //validation for text entry, ex. onTextEntryValidator(startDate, endDate)
            onTextEntryStartFieldError: null,
            onTextEntryEndFieldError: null,
            minDateCalculation: null,
            maxDateCalculation: null,
            clearDatesOnInvalidMidnight: false
            // if your plugin is event-driven, you may provide callback capabilities 
            // for its events. call these functions before or after events of your 
            // plugin, so that users may "hook" custom functions to those particular 
            // events without altering the plugin's code
            //onSomeEvent: function () { }

        }



        // this will hold the merged default, and user-provided options
        // plugin's properties will be accessible like:
        // plugin.settings.propertyName from inside the plugin or
        // myplugin.settings.propertyName from outside the plugin
        // where "myplugin" is an instance of the plugin
        plugin.settings = {};
        plugin.settings.midnightTimeout = null;

        // the "constructor" method that gets called when the object is created
        // this is a private method, it can be called only from inside the plugin
        var init = function () {

            // the plugin's final properties are the merged default and 
            // user-provided options (if any)
            plugin.settings = $.extend({}, defaults, options);

            //hardcoded vars
            plugin.settings.inlineMode = true;
            plugin.settings.mode = "double";
            plugin.settings.$targetDatepicker = null;
            plugin.hideTimeout = null;
            plugin.endBlurHideTimeout = null; //custom timeout just for end date blur
            plugin.updateMode = "";
            //plugin.startDateUserFocused = true;
            //plugin.endDateUserFocused = true;

            // make the collection of target elements available throughout the plugin
            // by making it a public property
            plugin.el = el;

            //code here
            if (plugin.el !== null && plugin.el.length > 0) {
                //inline
                plugin.settings.$targetDatepicker = plugin.el;
                plugin.settings.inlineMode = true;
            }
            else {
                //popup
                plugin.settings.inlineMode = false;
                if (plugin.settings.startDateDom !== null) {
                    plugin.settings.$targetDatepicker = plugin.settings.startDateDom;
                }
                else {
                    if (plugin.settings.dateDom !== null) {
                        plugin.settings.$targetDatepicker = plugin.settings.dateDom;
                        plugin.settings.mode = "single";
                    }
                    else {
                        console.log("Neither startDateDom or dateDom are set, please set either one in settings object");
                    }

                }
            }
            if (plugin.settings.startDate !== "") {
                plugin.values.startDate = plugin.settings.startDate
            }
            if (plugin.settings.endDate !== "") {
                plugin.values.endDate = plugin.settings.endDate
            }
            if (plugin.settings.changeYear !== null && plugin.settings.changeYear == true) {
                plugin.values.changeYear = true;
            }
            if (typeof plugin.settings.numberOfMonths !== 'undefined' && plugin.settings.numberOfMonths !== null) {
                plugin.values.numberOfMonths = plugin.settings.numberOfMonths;
            }
            if (typeof plugin.settings.closeSelectedDelay !== null) {
                plugin.values.closeSelectedDelay = plugin.settings.closeSelectedDelay;
            }
            if (typeof plugin.settings.renderClearButton !== null) {
                plugin.values.renderClearButton = plugin.settings.renderClearButton;
            }
            if (typeof plugin.settings.twoWayBinding !== null) {
                plugin.values.twoWayBinding = plugin.settings.twoWayBinding;
            }

            //min start date
            if (plugin.settings.minDate !== null) {
                plugin.values.minDate = plugin.settings.minDate;
            }
            else {
                plugin.values.minDate = getTodaysDate();
            }
            //max start date
            if (plugin.settings.maxDate !== null) {
                plugin.values.maxDate = plugin.settings.maxDate;
            }
            //man max functions used for refreshing on midnight
            if (plugin.settings.minDateCalculation !== null) {
                plugin.values.minDateCalculation = plugin.settings.minDateCalculation;
            }
            else {
                plugin.values.minDateCalculation = null;
            }
            if (plugin.settings.maxDateCalculation !== null) {
                plugin.values.maxDateCalculation = plugin.settings.maxDateCalculation;
            }
            else {
                plugin.values.maxDateCalculation = null;
            }
            
            if (plugin.settings.clearDatesOnInvalidMidnight !== null) {
                plugin.values.clearDatesOnInvalidMidnight = plugin.settings.clearDatesOnInvalidMidnight;
            }
            var showOnEvent = "focus";
            /*if (!plugin.settings.inlineMode) {
                showOnEvent = "none";
            }*/
            plugin.settings.$targetDatepicker.datepicker({
                minDate: plugin.values.minDate,
                maxDate: plugin.values.maxDate,
                changeYear: plugin.values.changeYear,
                numberOfMonths: plugin.values.numberOfMonths,
                showOn: showOnEvent,
                //focusEnabled: false, //will not attempt to focus input after selection
                onSelect: function (dateText, inst) {
                    onSelectLogic(dateText, inst);
                },
                onClose: function () {
                    if (!plugin.settings.inlineMode) {
                        $(this).data('datepicker').inline = false;
                    }
                },
                beforeShowDay: function (date) {
                    //console.log("firebsd");
                    var year = date.getFullYear();
                    // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
                    var month = padNumber(date.getMonth() + 1);
                    var day = padNumber(date.getDate());
                    // This depends on the datepicker's date format
                    var dateString = month + "/" + day + "/" + year;
                    var returnVal = [true, ""];

                    //have parsed date we are rendering
                    if (plugin.values.startDate !== "" && plugin.values.endDate !== "") {
                        //two values! render
                        var startDateObject = getDateFromString(plugin.values.startDate);
                        var endDateObject = getDateFromString(plugin.values.endDate);
                        if ((startDateObject !== null && date.getTime() == startDateObject.getTime()) || (endDateObject !== null && date.getTime() == endDateObject.getTime())) {
                            returnVal = [true, "ui-state-active-parent"];
                        } else
                            if (startDateObject !== null && endDateObject !== null && date.getTime() > startDateObject.getTime() && date.getTime() < endDateObject.getTime()) {
                                // Enable date so it can be deselected. Set style to be highlighted
                                returnVal = [true, "ui-state-highlight-parent"];
                            } else
                                if (dateString == getTodaysDate()) {
                                    returnVal = [true, "current-day-unselected"];
                                }
                    }
                    //unselect min date
                    var dateOnly = date.setHours(0, 0, 0, 0);
                    if (plugin.values.minDate !== null) {
                        var isMinUnselectedDay = false;
                        if (jQuery.type(plugin.values.minDate) === "date") {
                            var minDateOnly = plugin.values.minDate.setHours(0, 0, 0, 0);
                            if (plugin.values.startDate == "" && dateOnly === minDateOnly) {
                                isMinUnselectedDay = true;
                            }
                        } else if (jQuery.type(plugin.values.minDate) === "string") {
                            var minDateOnly = getDateFromString(plugin.values.minDate).setHours(0, 0, 0, 0);
                            if (plugin.values.startDate == "" && dateOnly === minDateOnly) {
                                isMinUnselectedDay = true;
                            }
                        }
                        if (isMinUnselectedDay) {
                            returnVal[1] = "min-day-unselected";
                        }
                    }

                    //if renderClearButton
                    if (plugin.values.renderClearButton) {
                        //if dom el not there, create it
                        setTimeout(function () {
                            //console.log($(this));
                            var $currentDatepickerDom = $("#ui-datepicker-div");
                            if ($currentDatepickerDom.children(".clear-dates").length === 0) {
                                //create dom
                                $currentDatepickerDom.append("<button type=\"button\" class=\"clear clear-dates\">Clear</button>");
                                $currentDatepickerDom.children('button.clear-dates').click(function (e) {
                                    e.preventDefault();
                                    plugin.clearDates();
                                    if (plugin.settings.mode == "double") {
                                        plugin.settings.endDateDom.val("");
                                        plugin.settings.endDateDom.blur();
                                    }
                                });
                            }
                            //if ($this)
                        }, 0);
                    }

                    //allow custom functions
                    //currently set to only disable if custom function disables, will not allow
                    if (plugin.settings.beforeShowDay !== null) {
                        var customReturnVal = plugin.settings.beforeShowDay(date);
                        //custom function returned invalid
                        if (typeof customReturnVal !== 'undefined') {
                            if (customReturnVal[0] == false) {
                                returnVal[0] = customReturnVal[0];
                            }
                        }
                        else {
                            //just execute, return nothing
                        }
                    }

                    // Dates not in the array are left enabled, but with no extra style
                    return returnVal;
                },
                onChangeMonthYear: function (e) {
                    if (plugin.settings.onChangeMonthYear !== null) {
                        plugin.settings.onChangeMonthYear(e);
                    }
                }
            });


            //event binding
            if (plugin.settings.startDateDom !== null) {
                plugin.settings.startDateDom.on("click", function () {
                    plugin.settings.startDateDom.trigger('focus');
                    /*
                    //if (plugin.startDateUserFocused) {
                        plugin.settings.currentlySettingStartDate = true;
                        plugin.settings.currentlySettingEndDate = false;
                        //plugin.showStartDate();
                        //plugin.refreshDatepicker();
                        var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                        console.log(instance);
                        if (!instance.isAutomatedFocus) {
                            plugin.showStartDate();
                        }

                        if (plugin.endBlurHideTimeout !== null) {
                            clearTimeout(plugin.endBlurHideTimeout);
                        }
                   //}
                    */
                });
                plugin.settings.startDateDom.on("focus", function () {
                    //if (plugin.startDateUserFocused) {
                    //plugin.settings.currentlySettingStartDate = true;
                    //plugin.settings.currentlySettingEndDate = false;
                    //plugin.showStartDate();
                    //plugin.refreshDatepicker();
                    var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                    //console.log(instance);
                    if (!instance.isAutomatedFocus) {
                        plugin.showStartDate();
                        plugin.updateMode = "startDate";
                    }

                    if (plugin.endBlurHideTimeout !== null) {
                        clearTimeout(plugin.endBlurHideTimeout);
                    }
                    //}
                });
            }
            if (plugin.settings.endDateDom !== null) {
                plugin.settings.endDateDom.on("click", function () {
                    if (!plugin.settings.inlineMode) {
                        plugin.settings.endDateDom.trigger('focus');
                        //plugin.settings.$targetDatepicker.datepicker('show');
                        //plugin.showEndDate();
                        //plugin.setEndFocusWithoutEvents();
                    }
                });
                plugin.settings.endDateDom.on("focus", function () {
                    //if (plugin.endDateUserFocused) {

                    var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                    //console.log(instance);
                    if (!instance.isAutomatedFocus) {
                        plugin.updateMode = "endDate";
                    }

                    if (!plugin.settings.inlineMode) {
                        //plugin.settings.$targetDatepicker.datepicker('show');
                        // plugin.settings.$targetDatepicker.datepicker('showWithoutFocus');
                        plugin.showEndDate();

                        //console.log("tempStartDate", tempStartDate);
                        //setTimeout(function () { plugin.values.startDate = tempStartDate; plugin.settings.startDateDom.val(tempStartDate); }, 0);

                    }
                    //}
                });
            }
            if (plugin.settings.dateDom !== null) {
                plugin.settings.dateDom.on("focus", function () {
                    //nothing now
                });
            }

            //textbox entry (two-way) event binding
            if (plugin.values.twoWayBinding) {
                if (plugin.settings.mode == "double") {
                    if (plugin.settings.startDateDom !== null) {
                        plugin.settings.startDateDom.on("blur", function () {
                            //attempt to parse
                            var startDateTextboxValue = plugin.settings.startDateDom.val();
                            var newDate = getDateFromString(startDateTextboxValue);
                            if (newDate != null && newDate.getTime() === newDate.getTime()) {
                                //its a date
                                //console.log("startdateparse: ", startDateTextboxValue);
                                var validatedStartDate = getDateFromString(startDateTextboxValue);
                                //console.log("validatedStartDate raw", validatedStartDate);
                                //validate against rules
                                //console.log(plugin.settings.onTextEntryValidator);
                                if (plugin.settings.onTextEntryValidator !== null) {
                                    if (!plugin.settings.onTextEntryValidator(validatedStartDate, null)) {
                                        validatedStartDate = null;

                                        plugin.clearDates();
                                        if (plugin.settings.mode == "double") {
                                            plugin.settings.endDateDom.val("");
                                            plugin.settings.endDateDom.blur();
                                        }
                                        if (plugin.settings.onTextEntryStartFieldError !== null) {
                                            plugin.settings.onTextEntryStartFieldError();
                                        }
                                    }
                                }
                                //console.log("validatedStartDate", validatedStartDate);
                                if (validatedStartDate !== null) {
                                    plugin.values.startDate = getDateStringFromDate(validatedStartDate);
                                    plugin.settings.startDateDom.val(plugin.values.startDate);
                                }
                            }
                            else {
                                //clear both to prev
                                plugin.settings.startDateDom.val(plugin.values.startDate);
                                plugin.settings.endDateDom.val(plugin.values.endDate);
                            }

                        });
                    }
                    if (plugin.settings.endDateDom !== null) {
                        plugin.settings.endDateDom.on("blur", function () {
                            //attempt to parse
                            var endDateTextboxValue = plugin.settings.endDateDom.val();
                            var newDate = getDateFromString(endDateTextboxValue);
                            if (newDate != null && newDate.getTime() === newDate.getTime()) {
                                //its a date
                                var validatedEndDate = getDateFromString(endDateTextboxValue);

                                if (plugin.settings.onTextEntryValidator !== null) {
                                    if (!plugin.settings.onTextEntryValidator(null, validatedEndDate)) {
                                        validatedEndDate = null;
                                        plugin.settings.endDateDom.val("");
                                        if (plugin.settings.onTextEntryEndFieldError !== null) {
                                            plugin.settings.onTextEntryEndFieldError();
                                        }
                                    }
                                }
                                //console.log("validatedEndDate", validatedEndDate);
                                if (validatedEndDate !== null) {
                                    //console.log("and validatedEndDate is: " + validatedEndDate);
                                    plugin.values.endDate = getDateStringFromDate(validatedEndDate);
                                    plugin.settings.endDateDom.val(plugin.values.endDate);

                                    //may change this later, not sure if the malicious setting is either the 2 months display or the popup
                                    if (plugin.settings.inlineMode) {
                                        plugin.showEndDate();
                                    }
                                    else {
                                        //hide calendar if tabbed out, will need to be cancelled if tabbing backwards
                                        plugin.endBlurHideTimeout = setTimeout(function () {
                                            var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                                            $(instance.dpDiv).css("display", "none");
                                            plugin.settings.$targetDatepicker.datepicker("refresh");
                                            plugin.settings.$targetDatepicker.datepicker("hide");
                                        }, plugin.values.closeSelectedDelay);
                                    }

                                }
                            }
                            else {
                                //set end to prev
                                plugin.settings.endDateDom.val(plugin.values.endDate);
                            }
                        });
                    }

                    //fire user-provided change event on textbox changes
                    if (plugin.settings.onSelectChange !== null) {
                        if (plugin.settings.startDateDom !== null && plugin.settings.endDateDom !== null) {
                            plugin.settings.startDateDom.on("change", function () {
                                //console.log("changed start");
                                var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                                var newVal = getDateFromString($(this).val());
                                if (newVal !== null && (newVal.getTime() === newVal.getTime())) {
                                    onSelectLogic($(this).val(), instance);
                                }
                                else {
                                    if (plugin.settings.onDateParseError !== null) {
                                        plugin.settings.onDateParseError();
                                    }
                                }
                            });
                            plugin.settings.endDateDom.on("change", function () {
                                //console.log("changed end");
                                var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                                var newVal = getDateFromString($(this).val());
                                if (newVal !== null && (newVal.getTime() === newVal.getTime())) {
                                    onSelectLogic($(this).val(), instance);
                                }
                                else {
                                    if (plugin.settings.onDateParseError !== null) {
                                        plugin.settings.onDateParseError();
                                    }
                                }
                            });
                        }
                    }

                }
                else {
                    //single mode
                    if (plugin.settings.startDateDom !== null) {
                        plugin.settings.startDateDom.on("blur", function () {
                            //parse two dates, set internal values
                            var separator = " - ";
                            var startDateTextboxValue = "";
                            var endDateTextboxValue = "";
                            var bothDatesValue = plugin.settings.startDateDom.val();

                            if (bothDatesValue.indexOf(separator) > -1) {
                                startDateTextboxValue = bothDatesValue.substr(0, bothDatesValue.indexOf(separator));
                                endDateTextboxValue = bothDatesValue.substr(bothDatesValue.indexOf(separator) + separator.length);

                                //validate
                                if (startDateTextboxValue !== "" && endDateTextboxValue !== "") {
                                    var validatedStartDate = getDateFromString(startDateTextboxValue);
                                    var validatedEndDate = getDateFromString(endDateTextboxValue);

                                    if (plugin.settings.onTextEntryValidator !== null) {
                                        if (!plugin.settings.onTextEntryValidator(validatedStartDate, null)) {
                                            validatedStartDate = null;
                                        }
                                    }
                                    if (plugin.settings.onTextEntryValidator !== null) {
                                        if (!plugin.settings.onTextEntryValidator(null, validatedEndDate)) {
                                            validatedEndDate = null;
                                        }
                                    }

                                    //console.log("validatedStartDate", validatedStartDate);
                                    if (validatedStartDate !== null) {
                                        plugin.values.startDate = getDateStringFromDate(validatedStartDate);
                                        plugin.settings.startDateDom.val(plugin.values.startDate);
                                    }

                                    //console.log("validatedEndDate", validatedEndDate);
                                    if (validatedStartDate !== null) {
                                        plugin.values.endDate = getDateStringFromDate(validatedEndDate);
                                        plugin.settings.endDateDom.val(plugin.values.endDate);
                                    }
                                }

                            }
                        });
                    }

                }

            }

            //go to month of start date, if has one
            if (plugin.settings.startDate !== "" && plugin.values.startDate !== "") {
                var startDateObject = getDateFromString(plugin.values.startDate);
                plugin.settings.$targetDatepicker.datepicker('setDate', startDateObject);
            }

            refreshCurrentDate();
        }

        // public methods
        // these methods can be called like:
        // plugin.methodName(arg1, arg2, ... argn) from inside the plugin or
        // myplugin.publicMethod(arg1, arg2, ... argn) from outside the plugin
        // where "myplugin" is an instance of the plugin

        // a public method.
        plugin.getStartValue = function () {

            // code goes here
            return plugin.values.startDate;
        };
        plugin.getEndValue = function () {

            // code goes here
            return plugin.values.endDate;
        };
        plugin.clearDates = function () {
            plugin.values.startDate = "";
            plugin.values.endDate = "";
            plugin.settings.$targetDatepicker.datepicker("setDate", null);
        };
        //set with selected
        plugin.setStartDate = function (input) {
            plugin.values.startDate = input;
        };
        plugin.setEndDate = function (input) {
            plugin.values.endDate = input;
        };
        plugin.setStartEndDates = function (start, end) {
            plugin.values.startDate = start;
            plugin.values.endDate = end;
            plugin.settings.$targetDatepicker.datepicker("setDate", start);
            if (plugin.settings.startDateDom !== null) {
                plugin.settings.startDateDom.val(start);
            }
            if (plugin.settings.endDateDom !== null) {
                plugin.settings.endDateDom.val(end);
            }
        };
        plugin.refreshDatepicker = function () {
            plugin.settings.$targetDatepicker.datepicker("refresh");
        };
        plugin.setMinDate = function (g_minDate) {
            plugin.settings.minDate = g_minDate;
            plugin.values.minDate = g_minDate;
            //set datepicker val
            plugin.settings.$targetDatepicker.datepicker('option', 'minDate', plugin.values.minDate);
        };
        plugin.setMaxDate = function (g_maxDate) {
            plugin.settings.maxDate = g_maxDate;
            plugin.values.maxDate = g_maxDate;
            //set datepicker val
            plugin.settings.$targetDatepicker.datepicker('option', 'maxDate', plugin.values.maxDate);
        };
        //These can only safely be used in inline mode, otherwise they will set the datpicker value
        plugin.showStartDate = function () {
            if (plugin.values.startDate !== "") {
                //var date = plugin.values.startDate;
                //plugin.settings.$targetDatepicker.datepicker("setDate", date);
                var date = plugin.values.startDate;
                var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                // plugin.settings.$targetDatepicker.datepicker('gotoDate', plugin.values.startDate);
            }
        };
        plugin.showEndDate = function () {
            if (plugin.values.endDate !== "") {
                var date = plugin.values.endDate;
                var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                // plugin.settings.$targetDatepicker.datepicker('gotoDate', plugin.values.endDate);
            }
        };
        plugin.hideDatepicker = function () {
            if (!plugin.settings.inlineMode) {
                var instance = $.datepicker._getInst(plugin.settings.$targetDatepicker[0]);
                $(instance.dpDiv).css("display", "none");
                //plugin.settings.$targetDatepicker.datepicker("refresh");
                plugin.settings.$targetDatepicker.datepicker("hide");
            }
        };
        plugin.getUpdateMode = function () {

            // code goes here
            return plugin.updateMode;
        };

        // private methods
        var refreshCurrentDate = function () {
            var self = this;
            var midnight = new Date();
            midnight.setHours(24, 0, 0, 0);
            var millisecondsUntilMidnight = (midnight.getTime() - new Date().getTime());
            //ensure fires after midnight
            //millisecondsUntilMidnight += 1;
            //console.log("secondsUntilMidnight: " + (millisecondsUntilMidnight / 1000));
            if (plugin.settings.midnightTimeout !== null) {
                clearTimeout(plugin.settings.midnightTimeout);
            }
            plugin.settings.midnightTimeout = setTimeout(function () {
                //console.log("midnight callout: " + millisecondsUntilMidnight + "or minutes: " + (millisecondsUntilMidnight / 1000 / 60) + " or hours: " + (millisecondsUntilMidnight / 1000 / 60 / 60));
                //refresh datepicker
                //console.log("refreshed datepicker " + plugin.settings.$targetDatepicker.attr("class") + " - next hours: " + (millisecondsUntilMidnight / 1000 / 60 / 60));

                //set new minDate
                var newMinDate = null;
                if (plugin.values.minDateCalculation !== null) {
                    //console.log("setting min date to: " + plugin.values.minDateCalculation());
                    newMinDate = plugin.values.minDateCalculation();

                    //clear if set to do so
                    if (plugin.values.clearDatesOnInvalidMidnight && newMinDate !== null && plugin.values.startDate !== "") {
                        //validate start against min
                        if (getDateFromString(plugin.values.startDate) < getDateFromString(newMinDate)) {
                            plugin.clearDates();
                            if (plugin.settings.startDateDom !== null) {
                                plugin.settings.startDateDom.val("");
                                plugin.settings.startDateDom.blur();
                            }
                            if (plugin.settings.endDateDom !== null) {
                                plugin.settings.endDateDom.val("");
                            }
                            if (plugin.settings.mode == "double") {
                                plugin.settings.endDateDom.val("");
                                plugin.settings.endDateDom.blur();
                            }
                            //clear dom
                        }
                    }

                    plugin.setMinDate(newMinDate);

                }
                if (plugin.values.maxDateCalculation !== null) {
                    //console.log("setting max date to: " + plugin.values.maxDateCalculation());
                    plugin.setMaxDate(plugin.values.maxDateCalculation());
                }
                

                //plugin.refreshDatepicker(); // isnt refreshing?
                plugin.hideDatepicker();

                //recursive
                refreshCurrentDate();
            }, millisecondsUntilMidnight); //millisecondsUntilMidnight
        };

        //date helpers
        var getTodaysDate = function () {
            var today = new Date();
            var dd = today.getDate();

            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            }
            today = mm + '/' + dd + '/' + yyyy;
            return today;
        };
        var padNumber = function (number) {
            var ret = new String(number);
            if (ret.length == 1) ret = "0" + ret;
            return ret;
        };
        var getDateFromString = function (date_string) {
            var myDate = null;
            //console.log("getting date from string: " + date_string);
            if (date_string !== null && date_string !== "") {
                date_string = date_string.replace(/\-/g, '/');
                if (date_string.indexOf("/") > -1) {
                    //split
                    var parts = date_string.split('/');

                    //year
                    if (parts.length >= 3 && parts[2].length == 2) {
                        parts[2] = "20" + parts[2];
                    }
                    //console.log("getDateFromString result: ", parts);
                    myDate = new Date(parts[2] * 1, parts[0] - 1, parts[1]);
                }
            }
            return myDate;
        };
        var getDateStringFromDate = function (date_obj) {
            var returnString = "";
            if (date_obj != null) {
                var year = date_obj.getFullYear();
                // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
                var month = padNumber(date_obj.getMonth() + 1);
                var day = padNumber(date_obj.getDate());
                returnString = month + "/" + day + "/" + year;
            }
            return returnString;
        };

        var onSelectLogic = function (dateText, inst) {
            var selectedDateObject = getDateFromString(dateText);
            var keepOpen = false; //stay open
            var closingPopup = false; //close with delay
            var newStartValue = plugin.values.startDate;
            var newEndValue = plugin.values.endDate;
            //console.log("plugin.values.startDate", plugin.values.startDate);
            //console.log("plugin.values.endDate", plugin.values.endDate);


            if (plugin.values.startDate == "" && plugin.values.endDate == "") {
                //both empty
                //set start date always
                newStartValue = dateText;
                //plugin.values.startDate = dateText;
                if (!plugin.settings.inlineMode) {
                    keepOpen = true;
                }
            }
            else if (plugin.values.endDate == "") {
                //console.log("has start no end");
                //has start, no end
                if (selectedDateObject < getDateFromString(plugin.values.startDate)) { //date before start?
                    //reset, new start
                    newStartValue = dateText;
                    //plugin.values.startDate = dateText;
                    if (!plugin.settings.inlineMode) {
                        keepOpen = true;
                    }
                }
                else {
                    if (plugin.updateMode === "endDate") {
                        //plugin.updateMode = "endDate";
                        newEndValue = dateText;
                        //plugin.values.endDate = dateText;
                        //stop users from selecting too far ahead a start date?
                        if (plugin.values.closeSelectedDelay > 0) {
                            if (!plugin.settings.inlineMode) {
                                inst.inline = true;
                            }
                        }
                    }
                    else {
                        //finish
                        //newStartValue = dateText;
                        newEndValue = dateText;
                        if (plugin.values.closeSelectedDelay > 0) {
                            if (!plugin.settings.inlineMode) {
                                inst.inline = true;
                            }
                        }
                    }

                }
            }
            else {
                //start empty, has end, or both have value
                //plugin.values.startDate = dateText;
                //plugin.values.endDate = "";

                if (plugin.updateMode === "startDate") {
                    newStartValue = dateText;
                    newEndValue = "";
                    if (!plugin.settings.inlineMode) {
                        keepOpen = true;
                    }
                }
                else {
                    newEndValue = dateText;
                    if (plugin.values.closeSelectedDelay > 0) {
                        if (!plugin.settings.inlineMode) {
                            inst.inline = true;
                        }
                    }
                }
                
            }

            if (plugin.settings.customSetOnSelect !== null) {
                var customReturnVal = plugin.settings.customSetOnSelect(selectedDateObject);
                //custom function returned invalid
                if (typeof customReturnVal !== 'undefined' && customReturnVal.length > 0) {
                    newStartValue = customReturnVal[0];
                    newEndValue = customReturnVal[1];
                    if (customReturnVal[1] === "") {
                        if (!plugin.settings.inlineMode) {
                            keepOpen = true;
                        }
                    }
                }
            }
            plugin.values.startDate = newStartValue;
            plugin.values.endDate = newEndValue;
            //plugin.values.startDate = newStartValue;
            //plugin.values.endDate = newEndValue;

            //console.log("newStartValue", newStartValue);
            //console.log("newEndValue", newEndValue);

            //set values of input fields
            if (plugin.settings.mode == "double") {
                if (plugin.values.startDate !== "") {
                    plugin.settings.startDateDom.val(plugin.values.startDate);
                }
                else {
                    plugin.settings.startDateDom.val("");
                }
                if (plugin.values.endDate !== "") {
                    plugin.settings.endDateDom.val(plugin.values.endDate);
                } else {
                    plugin.settings.endDateDom.val("");
                }
                plugin.settings.startDateDom.blur();
                plugin.settings.endDateDom.blur();
            }
            else {
                //single, aggregate values as d1 - d2
                if (plugin.values.startDate !== "") {
                    plugin.settings.dateDom.val(plugin.values.startDate);
                }
                else {
                    plugin.settings.dateDom.val("");
                }
                if (plugin.values.endDate !== "") {
                    plugin.settings.dateDom.val(plugin.values.startDate + " - " + plugin.values.endDate);
                }
                plugin.settings.dateDom.blur();
            }

            if (keepOpen) {
                inst.inline = true;
            }
            else {
                if (!plugin.settings.inlineMode) {
                    if (plugin.values.closeSelectedDelay > 0) {
                        closingPopup = true;
                    }
                    else {
                        inst.inline = false; //just close now
                    }
                }
            }
            //console.log("closingPopup: " + closingPopup + ". keepOpen: " + keepOpen);
            //close datepicker popup with delay, if a delay is set and not inline picker
            if (closingPopup) {
                plugin.hideTimeout = setTimeout(function () {
                    //console.log("firing closing popup");
                    inst.inline = false;
                    $('#' + inst.id).datepicker('hide');
                    if (plugin.settings.mode == "double") {
                        plugin.settings.startDateDom.blur();
                        plugin.settings.endDateDom.blur();
                    }
                    else {
                        plugin.settings.dateDom.blur();
                    }
                }, plugin.values.closeSelectedDelay);
            }

            if (plugin.settings.onSelect !== null) {
                plugin.settings.onSelect();
            }
        }
        // call the "constructor" method
        init();

    }
})(jQuery);